import { useEffect } from 'react'
import { Box, Typography } from '@mui/material'

import { captureError } from '@/utils/SentryUtilities'
import { error404 } from '@/constants/sentryErrors'
import { useRouter } from 'next/router'
import { FormattedMessage } from 'react-intl'

export default function Custom404() {
  const { asPath: slug } = useRouter()

  useEffect(() => {
    captureError(error404, `404 page in ${slug}`, { slug })
  }, [])

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        textAlign: 'center',
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box sx={{ display: 'grid', gridAutoRows: 'max-content', gap: '10px' }}>
        <Typography component='h1' variant='h1'>
          404
        </Typography>
        <Typography>
          <FormattedMessage id='selfOnboardingPage.landingPage.notFound' />
        </Typography>
      </Box>
    </Box>
  )
}
